import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import dva from 'dva'
import createHistory from 'history/createBrowserHistory'
import createLoading from 'dva-loading'
import nprogressDva from 'dva-nprogress'
import 'nprogress/nprogress.css'

import * as serviceWorker from './serviceWorker'
import './index.scss'

// 1. Initialize
const app = dva({
  history: createHistory({
    basename: '/'
  }),
  // onError(e) {
  //   message.error(e.message, ERROR_MSG_DURATION);
  // },
})

// 2. Plugins
app.use(createLoading())
app.use(nprogressDva())

// 3. Model
// Moved to router.js
// app.model()

// 4. Router
app.router(require('./router.js').default)

// 5. Start
app.start('#root')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
