import React from 'react'
import { Router, Route } from 'dva/router'
import dynamic from 'dva/dynamic'
import { LocaleProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
// import ReactGA from 'react-ga'

// 初始化 google 分析
// ReactGA.initialize('UA-123875641-1')

// 路由变化回调
const routerChangeCb = () => {
  // ReactGA.pageview(window.location.hash.split('?')[0])
  // 路由变化时页面滚回至头部
  window.scrollTo(0, 0)
}

class Index extends React.Component {
  componentDidMount() {
    this.props.history.replace('/conference/hbasecon-asia-2019')
  }
  render() {
    return ''
  }
}

function RouterConfig({ history, app }) {
  return (
    <LocaleProvider locale={zhCN}>
      <Router onUpdate={routerChangeCb} history={history}>
        <div>
          <Route
            path="/"
            component={Index}
            exact
          />
          <Route
            path="/conference/hbasecon-asia-2019"
            component={dynamic({
              app,
              component: () => import('./scenes/Index'),
            })}
            exact
          />
          <Route
            path="/conference/hbasecon-asia-2019/apply"
            component={dynamic({
              app,
              component: () => import('./scenes/Apply'),
              models: () => [import('./scenes/Apply/model.js')]
            })}
            exact
          />
          <Route
            path="/conference/hbasecon-asia-2019/apply/success"
            component={dynamic({
              app,
              component: () => import('./scenes/ApplySuccess'),
            })}
          />
          <Route exact path="/conference/hbasecon-asia-2019/apply/share" component={dynamic({
            app,
            component: () => import('./scenes/Share')
          })}
          />
        </div>
      </Router>
    </LocaleProvider>
  )
}

export default RouterConfig
